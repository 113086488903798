import Box from "@material-ui/core/Box";
import { XIcon } from "../icons/XIcon";
import Typography from "@material-ui/core/Typography";
import FormSelect from "../../../components/ui/FormContent/formSelect";
import FormInput from "../../../components/ui/FormContent/formInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import { ArrowBack } from "../icons/ArrowBack";
import Drawer from "@material-ui/core/Drawer";
import React, { useState, useEffect, Fragment } from "react";
import useStyles from "../styles";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addPartnerTradeVariant,
  createTrade,
  getTradesList,
  updatePartnerTrade,
  updatePartnerTradeVariant,
} from "../../../services/partners/tradesService";
import { useFilterDialog } from "../context/FilterDialogContext";
import { CityStateFilterPage } from "./CityStateFilterPage";
import Integer from "lodash";
import { TradeVariantForm } from "./TradeVariantForm";
import { useGetUserId } from "../context/UserIdContext";
import LoadingIndicator from "../../../components/common/LoadingIndicator/loadingIndicator";

export function AddTradeDrawer({
  drawerState,
  toggleDrawer,
  handleToggleDrawer,
  tradeToEdit,
  isEditingTrade,
  setIsEditingTrade,
  isVariant,
  setIsVariant,
  workItemId,
  addingVariant,
  setAddingVariant,
  partnerTradeId,
}) {
  const classes = useStyles();
  const tradesQuery = useQuery("partnerTradesList", async () => {
    const { data } = await getTradesList();
    return data.data.trades;
  });
  // Initialize state based on whether we're editing or creating a new trade
  const [data, setData] = useState({
    trade_id: "",
    name: "",
    fixed_rate: 0,
    hourly_rate: 0,
    sqf_rate: 0,
    zip_codes: "",
    cities: "",
    states: "",
    // Add more fields if necessary
  });
  const [variantForms, setVariantForms] = useState([]);
  const handleAddVariantForm = () => {
    setVariantForms((prevForms) => [
      ...prevForms,
      {
        variant_name: "",
        hourly_rate: "",
        fixed_rate: "",
        sqf_rate: "",
        states: "",
        cities: "",
        zip_codes: "",
      },
    ]);
  };
  const deleteVariantCB = (index) => {
    setVariantForms((prev) => {
      const updatedForms = [...prev];
      updatedForms.splice(index, 1);
      return updatedForms;
    });
  };

  const [errors, setErrors] = useState([]);
  const [filterType, setFilterType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [filterCities, setFilterCities] = useState([]);
  const [filterStates, setFilterStates] = useState([]);
  const [filterZipCodes, setFilterZipCodes] = useState([]);
  const [removedStates, setRemovedStates] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [zipCodesList, setZipCodesList] = useState([]);
  const [variantErrors, setVariantErrors] = useState([]);
  const [open, setOpen] = useState(false);
  const [variantHasError, setVariantHasError] = useState(false);
  const [failedSubmitVariant, setFailedSubmitVariant] = useState(false);
  const { handleDialogueOpen, setIsEditingVariant, variantsLocations } =
    useFilterDialog();
  const { userId } = useGetUserId();
  // Effect to set data when tradeToEdit changes
  useEffect(() => {
    if (isEditingTrade && tradeToEdit) {
      tradeToEdit.states.forEach((state) => {
        setFilterStates((prev) => [...prev, state.abbreviation]);
        setStatesList((prev) => [...prev, state.name]);
      });
      tradeToEdit.cities.forEach((city) => {
        setFilterCities((prev) => [...prev, Integer.parseInt(city.id)]);
        setCitiesList((prev) => [...prev, city.city_name]);
      });
      tradeToEdit.zip_codes.forEach((zip) => {
        setFilterZipCodes((prev) => [...prev, Integer.parseInt(zip.id)]);
        setZipCodesList((prev) => [...prev, zip.zip]);
      });
      if (isVariant) {
        setData({
          partner_trade_variation_id: tradeToEdit.id || "",
          fixed_rate: tradeToEdit.fixed_rate || 0,
          hourly_rate: tradeToEdit.hourly_rate || 0,
          sqf_rate: tradeToEdit.sqf_rate || 0,
          name: tradeToEdit.name || "",
        });
      } else {
        setData({
          partner_trade_id: tradeToEdit.id || "",
          fixed_rate: tradeToEdit.fixed_rate || 0,
          hourly_rate: tradeToEdit.hourly_rate || 0,
          sqf_rate: tradeToEdit.sqf_rate || 0,
        });
      }
    } else {
      setData({
        trade_id: "0",
        fixed_rate: 0,
        hourly_rate: 0,
        sqf_rate: 0,
      });
    }
  }, [
    tradeToEdit,
    isEditingTrade,
    isVariant,
    setFilterStates,
    setFilterCities,
    setFilterZipCodes,
  ]);

  const queryClient = useQueryClient();
  function onSubmitEditing() {
    setIsLoading(true);

    let states_added = [];
    let cities_added = [];
    let zip_codes_added = [];
    let states_removed = [];
    let cities_removed = [];
    let zip_codes_removed = [];
    if (tradeToEdit.cities.length > 0) {
      tradeToEdit.cities.forEach((city) => {
        if (!filterCities.includes(city.id)) {
          cities_removed.push(city.id);
        }
      });
      filterCities.forEach((city) => {
        if (!tradeToEdit.cities.some((c) => c.id === city)) {
          cities_added.push(city);
        }
      });
    } else {
      cities_added = filterCities;
    }
    if (tradeToEdit.states.length > 0) {
      tradeToEdit.states.forEach((state) => {
        if (!filterStates.includes(state.abbreviation)) {
          states_removed.push(state.abbreviation);
        }
      });
      filterStates.forEach((state) => {
        if (!tradeToEdit.states.some((s) => s.abbreviation === state)) {
          states_added.push(state);
        }
      });
    } else {
      states_added = filterStates;
    }
    if (tradeToEdit.zip_codes.length > 0) {
      tradeToEdit.zip_codes.forEach((zip) => {
        if (!filterZipCodes.includes(zip.id)) {
          zip_codes_removed.push(zip.id);
        }
      });
      filterZipCodes.forEach((zip) => {
        if (!tradeToEdit.zip_codes.some((z) => z.id === zip)) {
          zip_codes_added.push(zip);
        }
      });
    } else {
      zip_codes_added = filterZipCodes;
    }
    if (isVariant) {
      const object = {
        userId: userId,
        partner_id: tradeToEdit.partner_trade_id,
        data: {
          ...data,
          states_added: states_added,
          cities_added: cities_added,
          zip_codes_added: zip_codes_added,
          states_deleted: states_removed,
          cities_deleted: cities_removed,
          zip_codes_deleted: zip_codes_removed,
        },
      };
      editVariantMutation.mutate(object);
    } else {
      const object = {
        data: {
          ...data,
          states_added: states_added,
          cities_added: cities_added,
          zip_codes_added: zip_codes_added,
          states_deleted: states_removed,
          cities_deleted: cities_removed,
          zip_codes_deleted: zip_codes_removed,
        },
      };

      editMutation.mutate({
        partner_id: userId,
        data: object,
      });
    }
  }
  const mutation = useMutation(createTrade, {
    onSuccess: () => {
      queryClient.invalidateQueries("trades");
      setData({
        trade_id: "",
        fixed_rate: 0,
        hourly_rate: 0,
        sqf_rate: 0,
      });
      setFilterStates([]);
      setFilterCities([]);
      setFilterZipCodes([]);
      setCitiesList([]);
      setStatesList([]);
      handleToggleDrawer("right", false);
      setIsLoading(false);
      setVariantForms([]);
      setErrors([]);
      setZipCodesList([]);
    },
    onError: () => {
      setIsLoading(false);
    },
  });
  const addVariantMutation = useMutation(addPartnerTradeVariant, {
    onSuccess: () => {
      queryClient.invalidateQueries("tradeVariant");
      setData({
        trade_id: "",
        fixed_rate: 0,
        hourly_rate: 0,
        sqf_rate: 0,
      });
      handleToggleDrawer("right", false);
      setIsLoading(false);
      setAddingVariant(false);
      setFilterStates([]);
      setFilterCities([]);
      setFilterZipCodes([]);
      setZipCodesList([]);
      setCitiesList([]);
      setStatesList([]);
      setVariantForms([]);
    },
    onError: () => {
      setIsLoading(false);
    },
  });
  const editMutation = useMutation(updatePartnerTrade, {
    onSuccess: () => {
      queryClient.invalidateQueries("trades");
      setData({
        trade_id: "",
        fixed_rate: 0,
        hourly_rate: 0,
        sqf_rate: 0,
      });
      handleToggleDrawer("right", false);
      setIsLoading(false);
      setIsEditingTrade(false);
      setCitiesList([]);
      setStatesList([]);
      setFilterZipCodes([]);
      setFilterCities([]);
      setZipCodesList([]);
      setFilterStates([]);
    },
    onError: () => {
      setIsLoading(false);
    },
  });
  const editVariantMutation = useMutation(updatePartnerTradeVariant, {
    onSuccess: () => {
      queryClient.invalidateQueries("tradeVariant");
      setData({
        trade_id: "",
        fixed_rate: 0,
        hourly_rate: 0,
        sqf_rate: 0,
        name: "",
        zip_codes: "",
      });
      handleToggleDrawer("right", false);
      setIsLoading(false);
      setIsVariant(false);
      setCitiesList([]);
      setStatesList([]);
      setFilterZipCodes([]);
      setFilterCities([]);
      setZipCodesList([]);
      setFilterZipCodes([]);
      setZipCodesList([]);
      setCitiesList([]);
      setFilterStates([]);
      setErrors([]);
    },
    onError: () => {
      setIsLoading(false);
    },
  });
  const handleBlur = (event) => {
    setData({ ...data, [event.name]: event.value });
  };
  function resetForm() {
    setData({
      trade_id: "",
      fixed_rate: 0,
      hourly_rate: 0,
      sqf_rate: 0,
      zip_codes: "",
      cities: "",
      states: "",
    });
    setCitiesList([]);
    setStatesList([]);
    setZipCodesList([]);
    setFilterStates([]);
    setErrors([]);
    setFilterCities([]);
    setFilterZipCodes([]);
    setIsLoading(false);
    setAddingVariant(false);
    setVariantForms([]);
  }
  const handleVariantBlur = (e, index, states, zipcodes, cities, location) => {
    setVariantForms((prevForms) => {
      const updatedForms = [...prevForms];
      if (location) {
        updatedForms[index] = {
          ...updatedForms[index],
          [e.name]: e.value, // Ensure to use e.target.name
          zip_codes: zipcodes,
          cities: cities,
          states: states,
        };
      } else {
        updatedForms[index] = {
          ...updatedForms[index],
          [e.name]: e.value, // Ensure to use e.target.name
        };
      }
      return updatedForms;
    });
  };

  const onSubmit = async () => {
    let hasError = false;
    setFailedSubmitVariant(false);
    const object = {
      ...data,
      states: filterStates,
      cities: filterCities,
      zip_codes: filterZipCodes,
      variations: !addingVariant
        ? variantForms.map((form, index) => ({
            name: form.variant_name,
            hourly_rate: form.hourly_rate,
            fixed_rate: form.fixed_rate,
            sqf_rate: form.sqf_rate,
            states: variantsLocations[index].states,
            cities: variantsLocations[index].cities,
            zip_codes: variantsLocations[index].zip_codes,
          }))
        : undefined, // Only include variations if not adding a new variant
    };

    // Check if at least one of the rates is filled
    const isRateValid =
      !!object.fixed_rate || !!object.hourly_rate || !!object.sqf_rate;

    // Clear previous errors for rates
    const rateFields = ["fixed_rate", "hourly_rate", "sqf_rate"];
    rateFields.forEach((field) => {
      const index = errors.findIndex((error) => error.key === field);
      if (index !== -1) {
        setErrors((prevErrors) =>
          prevErrors.filter((error) => error.key !== field)
        );
      }
    });

    // Add errors if none of the rates are filled
    if (!isRateValid) {
      rateFields.forEach((field) => {
        const dummyError = {
          key: field,
          message: "At least one rate is required",
        };
        setErrors((prevErrors) => [...prevErrors, dummyError]);
      });
      hasError = true;
    }

    // const requiredFields = ["trade_id", "zip_codes", "cities", "states"];
    //variants dont need trade id to be filled up since it already comes with a value
    const requiredFields = addingVariant
      ? ["zip_codes", "cities", "states"]
      : ["trade_id", "zip_codes", "cities", "states"];
    requiredFields.forEach((field) => {
      if (!object[field] || object[field].length === 0 || object[field] === 0) {
        const dummyError = {
          key: field,
          message: "This field is required",
        };
        setErrors((prevErrors) => [...prevErrors, dummyError]);
        hasError = true;
      }
    });

    // If there are validation errors or variant errors, stop submission
    if (hasError || variantHasError) {
      setFailedSubmitVariant(true);
      return;
    }

    if (addingVariant) {
      const variantObject = {
        partner_trade_id: partnerTradeId,
        partner_id: userId,
        data: object, // Include the prepared object
      };
      await addVariantMutation.mutate(variantObject);
    } else {
      await mutation.mutate({
        data: object,
        partner_id: userId,
      });
    }
  };

  return (
    <Drawer
      anchor={"right"}
      open={drawerState["right"]}
      onClose={() => {
        handleToggleDrawer("right", false);
        setIsEditingTrade(false);
        setCitiesList([]);
        setStatesList([]);
        setZipCodesList([]);
        setFilterStates([]);
        setErrors([]);
        resetForm();
        setVariantForms([]);
        setVariantErrors([]);
        setVariantHasError(false);
      }}
    >
      <Box className={classes.drawerOuterContainer}>
        <Box className={classes.drawerContainer}>
          <Box>
            <Box className={classes.drawerTitleContainer}>
              <Box
                onClick={toggleDrawer("right", false)}
                className={classes.drawerCloseIcon}
              >
                <XIcon className={classes.drawerCloseIcon} />
              </Box>
              <Typography className={classes.drawerTitle}>
                {isEditingTrade ? "Edit Trade" : "Add Trade"}
              </Typography>
              <Typography className={classes.drawerInfoText}>
                {isEditingTrade
                  ? "Edit the details of this trade."
                  : "Provide details for the new trade."}
              </Typography>
            </Box>
            {(mutation.isLoading ||
              editMutation.isLoading ||
              editVariantMutation.isLoading ||
              addVariantMutation.isLoading) && <LoadingIndicator />}
            {!mutation.isLoading &&
              !editMutation.isLoading &&
              !editVariantMutation.isLoading &&
              !addVariantMutation.isLoading && (
                <Box className={classes.tradingDrawerFormContainer}>
                  <Box className={classes.drawerFieldsFormContainer}>
                    {!isVariant && (
                      <Box onClick={() => {}}>
                        <FormSelect
                          name="trade_id"
                          error={errors}
                          redirectIcon={true}
                          readonly={false} // Set to false if you want to allow editing
                          label="Trades Offered *"
                          gridSizes={[{ size: "md", val: 12 }]}
                          options={
                            tradesQuery.data
                              ? tradesQuery.data
                                  .map((trade) => ({
                                    value: trade.id,
                                    label: trade.trade_name,
                                  }))
                                  .sort((a, b) =>
                                    a.label.localeCompare(b.label)
                                  )
                              : []
                          }
                          disabled={addingVariant}
                          value={
                            addingVariant
                              ? workItemId
                              : tradeToEdit?.trade_id
                              ? tradeToEdit?.trade_id
                              : "0"
                          }
                          styleOverride={{ minWidth: "321px" }}
                          handleBlur={handleBlur}
                        />
                      </Box>
                    )}
                    {(addingVariant || isVariant) && (
                      <FormInput
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="name"
                        label="Variant Name *"
                        error={errors}
                        value={data.name}
                        readOnly={false}
                        styleOverride={{ minWidth: "114px" }}
                        handleBlur={handleBlur}
                      />
                    )}

                    <Typography className={classes.standardPriceText}>
                      STANDARD PRICE
                    </Typography>
                    <Box className={classes.ratesFormContainer}>
                      <FormInput
                        gridSizes={[{ size: "md", val: 6 }]}
                        name="hourly_rate"
                        type="number"
                        label="Hourly Rate *"
                        value={data.hourly_rate}
                        readOnly={false}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        error={errors}
                        styleOverride={{ minWidth: "114px" }}
                        handleBlur={handleBlur}
                      />
                      <FormInput
                        gridSizes={[{ size: "md", val: 6 }]}
                        name="fixed_rate"
                        label="Fixed Rate *"
                        type="number"
                        value={data.fixed_rate}
                        readOnly={false}
                        error={errors}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        styleOverride={{ minWidth: "114px" }}
                        handleBlur={handleBlur}
                      />
                    </Box>
                    <FormInput
                      gridSizes={[{ size: "md", val: 12 }]}
                      name="sqf_rate"
                      label="Sq Footage Rate *"
                      value={data.sqf_rate}
                      type="number"
                      readOnly={false}
                      error={errors}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      styleOverride={{ minWidth: "114px" }}
                      handleBlur={handleBlur}
                    />
                    <Box
                      onClick={() => {
                        setOpen(true);
                        setIsEditingVariant(true);
                        setFilterType("states");
                      }}
                    >
                      <FormSelect
                        name="states"
                        redirectIcon={true}
                        readonly={true} // Set to false if you want to allow editing
                        error={errors}
                        label="States Serviced *"
                        gridSizes={[{ size: "md", val: 12 }]}
                        options={[
                          {
                            value: 1,
                            label:
                              statesList.length > 0
                                ? `
                    ${statesList?.map((state) => state).join(", ")}
                    `
                                : "States Serviced",
                          },
                          { value: 2, label: "Role 2" },
                        ]}
                        value={data.states || 1}
                        styleOverride={{ minWidth: "321px" }}
                        handleBlur={handleBlur}
                      />
                    </Box>

                    <Box
                      onClick={() => {
                        setOpen(true);
                        setIsEditingVariant(true);
                        setFilterType("cities");
                      }}
                    >
                      <FormSelect
                        name="cities"
                        label="Cities Serviced *"
                        error={errors}
                        redirectIcon={true}
                        readonly={true} // Set to false if you want to allow editing
                        gridSizes={[{ size: "md", val: 12 }]}
                        options={[
                          {
                            value: 1,
                            label:
                              citiesList.length > 0
                                ? ` 
                            ${citiesList.join(", ")} 
                            `
                                : "Cities Serviced",
                          },
                          { value: 2, label: "Role 2" },
                        ]}
                        value={data.cities || 1}
                        styleOverride={{ minWidth: "321px" }}
                        handleBlur={handleBlur}
                      />
                    </Box>
                    <Box
                      onClick={() => {
                        setOpen(true);
                        setIsEditingVariant(true);
                        setFilterType("zip_codes");
                      }}
                    >
                      <FormSelect
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="zip_codes"
                        error={errors}
                        label="Zip Codes *"
                        redirectIcon={true}
                        readonly={true} // Set to false if you want to allow editing
                        options={[
                          {
                            value: 1,
                            label:
                              zipCodesList.length > 0
                                ? `${zipCodesList.join(", ")}`
                                : "Zip Codes",
                          },
                          { value: 2, label: "Role 2" },
                        ]}
                        value={data.zip_codes || 1}
                        styleOverride={{ width: "100%" }}
                        handleBlur={handleBlur}
                      />
                    </Box>
                  </Box>

                  {!isEditingTrade && (
                    <Fragment>
                      {variantForms.map((form, index) => (
                        <Fragment key={index}>
                          <TradeVariantForm
                            data={form}
                            handleBlur={handleVariantBlur}
                            index={index}
                            setVariantErrors={setVariantErrors}
                            variantErrors={variantErrors}
                            setVariantHasError={setVariantHasError}
                            openDialogue={handleDialogueOpen}
                            failedSubmitVariant={failedSubmitVariant}
                            deleteVariant={deleteVariantCB}
                          />
                        </Fragment>
                      ))}
                      {!addingVariant && (
                        <Button
                          className={classes.addPricingVariationButton}
                          onClick={handleAddVariantForm}
                        >
                          Add Pricing Variation
                        </Button>
                      )}
                    </Fragment>
                  )}
                </Box>
              )}
          </Box>
        </Box>
        <Box className={classes.drawerFieldsFormContainer}></Box>
        <Box className={classes.drawerTradingButtonsContainer}>
          <Button
            onClick={toggleDrawer("right", false)}
            startIcon={<ArrowBack />}
            className={classes.goBackButton}
            disableRipple={true}
          >
            Go Back
          </Button>
          <Button
            disabled={isLoading}
            onClick={isEditingTrade ? onSubmitEditing : onSubmit}
            className={classes.saveChangesButton}
          >
            {isEditingTrade ? "Save Changes" : "Save Trade"}
          </Button>
        </Box>
      </Box>
      {open ? (
        <CityStateFilterPage
          filterCities={filterCities}
          setFilterCities={setFilterCities}
          filterStates={filterStates}
          setFilterStates={setFilterStates}
          filterZipCodes={filterZipCodes}
          setFilterZipCodes={setFilterZipCodes}
          setCitiesList={setCitiesList}
          setStatesList={setStatesList}
          setZipCodesList={setZipCodesList}
          statesList={statesList}
          citiesList={citiesList}
          zipCodesList={zipCodesList}
          setOpen={setOpen}
          setRemovedStates={setRemovedStates}
          filterType={filterType}
        />
      ) : null}
    </Drawer>
  );
}
