import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    // show scrollbar
    overflowY: "auto",
    overflowX: "hidden",
  },
  pageBodyWrapper: {
    display: "flex",
    maxHeight: "100%",
    flexDirection: "column",
    height: "100%",
    alignItems: "stretch",
    maxWidth: "100%",
    marginLeft: 22,
    "@media (max-width: 600px)": {
      marginLeft: 8,
      marginRight: 8,
    },
  },
  partnersWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  headerBox: {
    border: "1px solid #ECECEC",
    padding: "16px",
    borderRadius: "4px",
    minHeight: "88px",
    display: "flex",
    maxWidth: "100%",
  },
  headerBoxEdit: {
    border: "1px solid #ECECEC",
    padding: "16px",
    borderRadius: "4px",
    display: "flex",
    maxWidth: "100%",
    "& label": {
      fontSize: 14,
      fontWeight: 600,
      marginBottom: 8,
    },
  },
  formControl: {
    paddingBottom: theme.spacing(1),
  },
  formBtn: {
    textTransform: "capitalize",
    backgroundColor: "#4F98BC",
    padding: "8px, 32px, 8px, 32px",
    radius: "4px",
    lineHeight: "20px",
    color: "white",
    fontWeight: 600,
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#ffffff !important",
      boxShadow: "none !important",
    },
    "&:active": {
      boxShadow: "none !important",
      backgroundColor: "#3c52b2 !important",
    },
    "&: .MuiButton-root": {
      "&:hover": {
        backgroundColor: "#3c52b2 !important",
      },
    },
    "&.MuiButton-root:hover": {
      backgroundColor: "#4F98BC !important",
      opacity: 0.9,
    },
  },
  buttonContainer: {
    gap: 16,
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  officeBuildingIconHolder: {
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    backgroundColor: "#78C1CE",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  externalHeaderWrapper: {
    display: "flex",
    flexDirection: "row",
    // marginBottom: 16,
    width: "100%",
  },
  innerHeaderWrapper: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 16,
    gap: 12,
    width: "100%",
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  companyTitle: {
    fontSize: 24,
    fontWeight: 700,
    // height: 24,
    color: "#4D4D4D",
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
    fontWeight: 400,
    fontSize: 12,
    minHeight: 16,
    justifyContent: "space-between",
    color: "#747474",
    flexWrap: "wrap",
  },
  individualInfoWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
    justifyContent: "center",
  },
  verticalSeparator: {
    width: "1px",
    height: "18px",
    border: "1px solid #D9D9D9", // You can change the color as needed
  },
  tabsWrapper: {
    display: "flex",
    backgroundColor: "#a1a1a1",
    flexDirection: "column",
  },

  generalInformationWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  limitUserWrapperMobile: {
    "@media (max-width: 600px)": {
      width: "324px",
    },
  },
  generalInformationSubHeader: {
    fontSize: 18,
    marginTop: 22,
    fontWeight: 700,
    color: "#17457A",
    height: 22,
  },
  metricsInformationSubHeader: {
    fontSize: 18,
    fontWeight: 700,
    color: "#17457A",
    height: 22,
  },
  tradesOfferingSubheader: {
    fontSize: 18,
    fontWeight: 700,
    color: "#17457A",
    height: 22,
  },
  companyContactsSubheader: {
    fontSize: 14,
    fontWeight: 400,
    marginTop: 24,
    color: "#4D4D4D",
    height: 17,
  },
  companyContactFields: {
    display: "flex",
    flexDirection: "row",
    gap: 16,
    marginTop: 16,
    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  threeDotButton: {
    width: 44,
    height: 38,
    backgroundColor: "#FFFFFF",
    marginTop: 24,
    border: "1px solid #d6dde1",
    borderRadius: "8%",
    cursor: "pointer",
  },
  threeDotButtonContacts: {
    width: 44,
    height: 38,
    backgroundColor: "#FFFFFF",
    marginTop: 25,
    marginBottom: 16,
    border: "1px solid #d6dde1",
    borderRadius: "8%",
    cursor: "pointer",
    "@media (max-width: 600px)": {
      marginTop: 8,
      marginBottom: 16,
      width: "100%",
    },
  },
  addContactButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 32,
  },
  addContactButton: {
    width: 167,
    height: 40,
    backgroundColor: "#4F98BC",
    padding: "8px, 32px, 8px, 32px",
    radius: "4px",
    lineHeight: "20px",
    textTransform: "none",
    color: "white",
    fontWeight: 600,
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#ffffff !important",
      boxShadow: "none !important",
    },
    "&:active": {
      boxShadow: "none !important",
      backgroundColor: "#3c52b2 !important",
    },
    "&: .MuiButton-root": {
      "&:hover": {
        backgroundColor: "#3c52b2 !important",
      },
    },
    "&.MuiButton-root:hover": {
      backgroundColor: "#4F98BC !important",
      opacity: 0.9,
    },
  },
  secondAddContactButton: {
    width: 155,
    height: 40,
    backgroundColor: "#4F98BC",
    padding: "8px, 32px, 8px, 32px",
    radius: "4px",
    lineHeight: "20px",
    textTransform: "none",
    color: "white",
    fontWeight: 600,
    fontSize: 14,
    "&.MuiButton-root:hover": {
      backgroundColor: "#4F98BC !important",
      opacity: 0.9,
    },
  },
  addContactCancelButton: {
    width: 115,
    height: 40,
    backgroundColor: "white",
    border: "1px solid #4F98BC",
    padding: "8px, 32px, 8px, 32px",
    radius: "4px",
    radiusColor: "#4F98BC",
    lineHeight: "20px",
    textTransform: "none",
    color: "#4F98BC",
    fontWeight: 600,
    fontSize: 14,
  },
  viewDashboardButton: {
    width: 167,
    height: 40,
    backgroundColor: "#4F98BC",
    borderRadius: "4px",
    lineHeight: "20px",
    textTransform: "none",
    color: "white",
    fontWeight: 600,
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    "&:hover": {
      opacity: 0.8,
    },
  },
  dialogTitleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  dialogTitleInnerWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "12px",
    justifyItems: "center",
    "& svg": {
      color: "#4D4D4D",
    },
  },
  closeButton: {
    position: "absolute",
    right: "8px",
    top: "8px",
  },
  dialogTitle: {
    fontSize: 16,
    fontWeight: 700,
    textAlign: "center",
    paddingTop: 4,
    color: "#4D4D4D",
  },
  addContactFormWrapper: {
    display: "flex",
    maxWidth: "600px",
    flexDirection: "column",
    gap: 24,
  },
  addLocationFormWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    maxWidth: "400px",
  },
  addQuestionButtonActionContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 16,
    padding: "0px 16px 16px 16px",
  },
  ratingCommentsMainWrapper: {
    height: "100%",
    maxWidth: "100%",
    paddingTop: 24,
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: "#FBFBFB",
    "@media (max-width: 1280px)": {
      maxWidth: "100%",
      width: "100%",
    },
    "@media (max-width: 600px)": {
      paddingLeft: 0,
      maxWidth: "99%",
    },
  },
  ratingCommentsInnerWrapper: {
    backgroundColor: "#FFFFFF",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: 16,
    "@media (max-width: 1280px)": {
      maxWidth: "100%",
      width: "100%",
    },
  },
  ratingWrapper: {
    // height: 164,
    display: "flex",
    flexDirection: "column",
  },
  ratingTitle: {
    font: "Lato",
    fontSize: 18,
    marginTop: 8,
    fontWeight: 700,
    textAlign: "left",
    color: "#747474",
  },
  ratingInfoContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
  },
  ratingNumber: {
    fontSize: 14,
    fontWeight: 400,
    textAlign: "left",
    color: "#747474",
  },
  noReviewText: {
    fontSize: 14,
    fontWeight: 600,
    textAlign: "left",
    color: "#4D4D4D",
  },
  ratePartnerButton: {
    maxWidth: "100%",
    height: 40,
    padding: "8px 32px 8px 32px",
    borderRadius: "4px",
    backgroundColor: "#9CBB65",
    color: "white",
    fontSize: 14,
    font: "Open Sans",
    fontWeight: 600,
    marginTop: 16,
    textAlign: "center",
    textTransform: "none",
    "&.MuiButton-root:hover": {
      backgroundColor: "#9CBB65 !important",
      opacity: 0.9,
    },
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  accordionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    marginTop: 40,
    overflowX: "hidden",
  },
  accordionRoot: {
    maxWidth: "100%",
    boxShadow: "none",
    border: "1px solid #ECECEC",
    borderRadius: "4px",
    "@media (max-width: 600px)": {
      maxWidth: "344px",
    },
  },
  expandIcon: {
    transform: "rotate(270deg)", // Initially pointing right
    transition: "transform 0.1s",
  },
  expandIconExpanded: {
    transform: "rotate(180deg)", // Pointing down when expanded
    position: "absolute",
  },
  chipNotExpanded: {
    border: "1px solid #ECECEC",
    color: "#747474",
  },
  trueArrowDownEdit: {
    position: "absolute",
    top: 14,
  },
  arrowDownQuestionTest: {
    position: "absolute",
  },
  arrowDownQuestion: {
    right: 48,
  },
  arrowDownQuestionEdit: {
    right: 48,
    // bottom: 48,
    // bottom: 32,
  },
  chipExpanded: {
    border: "1px solid #4F98BC",
    color: "#4F98BC",
  },
  geographicalCoverageTitle: {
    font: "Lato",
    fontSize: 14,
    margin: 0,
    fontWeight: 400,
    color: "#4D4D4D",
  },

  accordionDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  accordionInnerDetailsContainer: {
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    gap: 8,
    overflowY: "scroll",
    maxHeight: 400,
  },
  accordionVariantStandard: {
    backgroundColor: "#F8F8F8",
    display: "flex",
    flexDirection: "column",
    // marginLeft: 16,
    // marginRight: 16,
    // marginBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
    gap: 16,
    radius: "4px",
  },
  accordionDetailsSubtitle: {
    font: "Open Sans",
    fontSize: 12,
    fontWeight: 600,
    color: "#4D4D4D",
  },
  threeDotButtonAccordion: {
    width: 40,
    height: 40,
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  accordionCityTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  infoChipsContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 4,
    flexWrap: "wrap",
    width: "572px",
  },
  infoChip: {
    backgroundColor: "#FFFFFF",
    color: "#747474",
    border: "1px solid #ECECEC",
  },
  addNewCoverageButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  drawerOuterContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  drawerContainer: {
    width: 382,
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  drawerTitleContainer: {
    display: "flex",
    paddingLeft: 32,
    paddingTop: 70,
    paddingBottom: 16,
    paddingRight: 28,
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    color: "#747474",
    backgroundColor: "#F8F8F8",
  },
  drawerTitle: {
    font: "Lato",
    fontSize: 18,
    fontWeight: 400,
  },
  drawerCloseIcon: {
    position: "absolute",
    left: 16,
    top: 36,
  },
  drawerInfoText: {
    font: "Nunito Sans",
    fontSize: 14,
    fontWeight: 400,
  },
  drawerFormContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 32,
    paddingLeft: 32,
    paddingRight: 32,
    gap: 24,
  },
  errorsButtonContainer: {
    marginTop: 32,
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  errorMissingFieldsText: {
    fontSize: 14,
    fontWeight: 400,
    marginLeft: 32,
    color: "#FF0000",
  },
  drawerButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: 32,
    marginRight: 28,
    paddingBottom: 32,
  },
  goBackButton: {
    fontSize: 14,
    width: 132,
    height: 40,
    weight: 400,
    textTransform: "none",
    color: "#4F98BC",
    backgroundColor: "white",
    "&.MuiButton-root:hover": {
      backgroundColor: "#FFFFFF !important",
      opacity: 0.9,
    },
    "&.MuiButton-root:active": {
      backgroundColor: "white",
    },
    //on click style
    "&.MuiButton-root": {
      "&:hover": {
        backgroundColor: "white",
      },
    },
  },
  saveChangesButton: {
    fontFamily: "Lato",
    fontSize: 14,
    width: 150,
    height: 40,
    weight: 700,
    textTransform: "none",
    color: "white",
    backgroundColor: "#4F98BC",
    "&.MuiButton-root:hover": {
      backgroundColor: "#4F98BC !important",
      opacity: 0.9,
    },
  },
  noTradesContainer: {
    display: "flex",
    position: "absolute",
    marginTop: 74,
    top: 0,
    left: "35%",
    flexDirection: "column",
    alignItems: "center",
    gap: 16,
  },
  bigNoContainer: {
    position: "relative",
  },
  notepadContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 12,
  },
  tradesTitleContainer: {
    marginTop: 24,
    marginBottom: 24,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tradesMultipleStatesContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  tradesOneCityContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  ratesContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
  },
  ratesText: {
    font: "Open Sans",
    fontSize: 12,
    fontWeight: 600,
    color: "#747474",
  },
  spanRatesText: {
    fontWeight: 400,
  },
  verticalSeparatorRates: {
    width: "1px",
    height: "18px",
    border: "1px solid #D9D9D9",
  },
  pinTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  standardPriceText: {
    font: "Open Sans",
    fontSize: 12,
    fontWeight: 600,
    color: "#4D4D4D",
  },
  ratesFormContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 24,
  },
  tradesDataContainer: {
    overflowY: "hidden",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  verticalTabsProgressContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "between",
  },
  progressContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 4,
    backgroundColor: "#F8F8F8",
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
  tabsMainWrapper: {
    display: "flex",
    flexDirection: "row",
    //column when mobile  responsive
    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  tabsWrapperRoot: {
    borderRight: "1px solid #e8e8e8",
    height: "600px",
    marginTop: "12px",
    display: "flex",
    width: 240,
    flexDirection: "column",
    justifyContent: "space-between",
    "@media (max-width: 600px)": {
      height: "auto",
      flexDirection: "row",
      borderRight: "none",
    },
  },
  limitTabWrapperMobile: {
    "@media (max-width: 600px)": {
      width: "324px",
    },
  },
  profileCompletionTitleIconContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  profileCompletionTitle: {
    fontSize: 12,
    fontWeight: 400,
    color: "#4D4D4D",
    lineHeight: "120%",
  },
  profileCompletionIcon: {
    width: 24,
    height: 24,
  },
  tabsContainerRoot: {
    overflowX: "auto",
    width: "100%",
    marginLeft: "24px",
    overflow: "hidden",
    "@media (max-width: 600px)": {
      marginLeft: 0,
    },
  },
  completeFieldsText: {
    fontSize: 14,
    fontWeight: 400,
    color: "#747474",
    marginBottom: theme.spacing(2),
  },
  profileList: {
    listStyleType: "none",
    paddingLeft: 0,
    margin: 0,
  },
  profileListItemText: {
    fontSize: 12,
    position: "relative",
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: "#4F98BC",
    textDecoration: "underline",
    textDecorationThickness: "1px",
    "& p": {
      fontSize: 12,
      cursor: "pointer",
      paddingLeft: 4,
    },
    "&::before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: "50%",
      transform: "translateY(-50%)",
      width: 5,
      height: 5,
      borderRadius: "50%",
      backgroundColor: "#D9D9D9",
    },
  },
  metricDataContainer: {
    display: "flex",
    flexDirection: "column",
    height: 79,
    backgroundColor: "#F8F8F8",
    padding: 16,
  },
  metricStatusContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
  },
  metricDataPercent: {
    font: "Lato",
    fontSize: 18,
    fontWeight: 700,
    color: "#747474",
  },
  metricNumbersContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  greenDot: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "#9CBB65",
  },
  whiteDot: {
    width: 8,
    height: 8,
    border: "1px solid #4D4D4D",
    borderRadius: "50%",
    backgroundColor: "white",
  },
  lightBlueDot: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "#BAE7FA",
  },
  purpleDot: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "#A67EC8",
  },
  darkBlueDot: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "#6973C6",
  },
  zeroContainer: {
    marginTop: 18,
    minHeight: 80,
  },
  metricsMainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  metricsMainHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 8,
  },
  metricsMainHeaderDates: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 8,
  },
  metricContainerTitleText: {
    font: "Lato",
    fontSize: 18,
    fontWeight: 700,
    color: "#17457A",
  },
  metricNumbersText: {
    font: "Lato",
    fontSize: 14,
    fontWeight: 400,
    color: "#747474",
  },
  ratingNumberSpan: {
    font: "Lato",
    fontSize: 18,
    fontWeight: 700,
    color: "#4D4D4D",
  },
  workTicketsRatingContainer: {
    border: "1px solid #ECECEC",
    display: "flex",
    flexDirection: "column",
    height: 92,
    padding: 16,
  },
  ratingNumberTextContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 16,
    alignItems: "center",
  },
  ratingTextSpan: {
    font: "Open Sans",
    fontSize: 14,
    weight: 600,
    color: "#4D4D4D",
  },
  ratingNumberContainer: {
    width: 35,
    height: 35,
    backgroundColor: "#328914",
    color: "white",
    fontSize: 18,
    font: "Lato",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  questionsTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  questionsSubheader: {
    fontSize: 18,
    fontWeight: 700,
    color: "#17457A",
    height: 22,
    paddingTop: 16,
  },
  questionsExpandIcon: {
    color: "#4D4D4D",
    position: "absolute",
    right: 48,
  },
  questionsExpandedIcon: {
    color: "#4D4D4D",
    left: 48,
    position: "absolute",
  },
  questionsListMenu: {
    position: "absolute",
    right: 0,
    top: 0,
    "&.MuiButtonBase-root:hover": {
      backgroundColor: "transparent",
    },
  },
  questionsListMenuExpanded: {
    top: 8,
  },
  questionAccordionRoot: {
    boxShadow: "none",
    border: "0px solid blue",
    position: "unset",
    borderWidth: 0,
    backgroundColor: "#F8F8F8",
    borderRadius: 0,
    "&.MuiExpansionPanel-root:before": {
      display: "none",
      backgroundColor: "transparent",
    },
    "&.MuiIconButton-root": {
      position: "absolute",
      right: "50",
      top: "0",
    },
  },
  accordionSummaryContainer: {
    "&.MuiAccordionSummary-root": {},
  },
  questionTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: "#747474",
  },
  questionBody: {
    fontSize: 14,
    fontWeight: 400,
    color: "#747474",
  },
  bodyTextarea: {
    padding: 8,
    borderColor: "#ECECEC",
    outline: "none",
    fontFamily: "Nunito Sans, Helvetica, Arial, sans-serif",
    borderRadius: 4,
  },
  addAnswerButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 16,
  },
  addAnswerButton: {
    width: 167,
    height: 40,
    border: "1px solid #4F98BC",
    backgroundColor: "#FFFFFF",
    padding: "8px, 32px, 8px, 32px",
    radius: "4px",
    lineHeight: "20px",
    textTransform: "none",
    color: "#4F98BC",
    fontWeight: 600,
    fontSize: 14,
  },
  questionsFlexContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    marginTop: 24,
  },
  findUserField: {
    width: 269,
    height: 40,
    padding: "4px",
    border: "1px solid #ECECEC",
    borderRadius: "4px",
    fontSize: 14,
  },
  sparklesIcon: {
    paddingLeft: 8,
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  createSearchContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 16,
    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  createUserButton: {
    width: 128,
    height: 40,
    backgroundColor: "white",
    padding: "8px, 32px, 8px, 32px",
    border: "1px solid #4F98BC",
    radius: "4px",
    lineHeight: "20px",
    textTransform: "none",
    color: "#4F98BC",
    fontWeight: 700,
    fontSize: 14,
  },
  createUserButtonFilled: {
    width: 128,
    height: 40,
    backgroundColor: "#4F98BC",
    padding: "8px, 32px, 8px, 32px",
    radius: "4px",
    lineHeight: "20px",
    textTransform: "none",
    color: "white",
    fontWeight: 700,
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#4F98BC",
    },
  },
  userFilterButton: {
    width: 103,
    height: 40,
    color: "#4F98BC",
    backgroundColor: "#EDF5F8",
    padding: "8px, 16px, 8px, 16px",
    radius: "4px",
    lineHeight: "20px",
    textTransform: "none",
    fontWeight: 400,
    fontSize: 14,
  },
  numberSpanContainer: {
    width: 23,
    height: 16,
    backgroundColor: "#4F98BC",
    display: "flex",
    color: "white",
    justifyContent: "center",
    marginLeft: 8,
    radius: 8,
    borderRadius: 8,
    alignItems: "center",
  },
  deleteAssignButtonContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 12,
  },
  deleteUserButton: {
    width: 92,
    height: 32,
    border: "1px solid #4F98BC",
    backgroundColor: "white",
    fontSize: 14,
    fontWeight: 700,
    textTransform: "none",
    fontFamily: "Lato",
    color: "#4F98BC",
  },
  assignLocationButton: {
    width: 144,
    height: 32,
    backgroundColor: "#4F98BC",
    color: "white",
    fontSize: 14,
    textTransform: "none",
    fontWeight: 700,
    fontFamily: "Lato",
    "&:hover": {
      backgroundColor: "#4F98BC",
      opacity: 0.8,
    },
  },
  userDrawerStepCount: {
    position: "absolute",
    right: 16,
    top: 36,
  },
  userDrawerStepCountText: {
    font: "Lato",
    fontSize: 14,
    fontWeight: 700,
    color: "#747474",
  },
  uploadPhotoContainer: {
    width: 321,
    height: 156,
    backgroundColor: "#FFFFFF",
    border: "1px dotted #D9D9D9",
    display: "flex",
    gap: 14,
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
    alignItems: "center",
  },
  uploadPhotoImage: {
    width: 60,
    height: 60,
    borderRadius: "50%",
    objectFit: "cover",
  },
  changePhotoContainer: {
    width: 321,
    height: 156,
    backgroundColor: "#FFFFFF",
    display: "flex",
    gap: 14,
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
    alignItems: "center",
  },
  uploadPhotoText: {
    font: "Open Sans",
    fontSize: 14,
    fontWeight: 400,
    color: "#4F98BC",
  },
  addUserInformationContainer: {
    width: 321,
    height: 60,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontFamily: "Open Sans",
    gap: 9,
    backgroundColor: "#EDF5F8",
    color: "#747474",
    paddingLeft: 16,
    fontSize: 12,
    fontWeight: 400,
  },
  userCreatedSuccessMainContainer: {
    width: 400,
    display: "flex",
    flexDirection: "column",
    gap: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  userSuccessIconTextContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    alignItems: "center",
  },
  userCreatedSuccessText: {
    font: "Open Sans",
    fontSize: 16,
    fontWeight: 600,
    color: "#4D4D4D",
  },
  userCreatedInfoText: {
    font: "Open Sans",
    fontSize: 14,
    fontWeight: 400,
    color: "#747474",
  },
  okaySuccessButton: {
    width: "100%",
    height: 40,
    backgroundColor: "#4F98BC",
    padding: "8px, 32px, 8px, 32px",
    radius: "4px",
    lineHeight: "20px",
    textTransform: "none",
    color: "white",
    fontWeight: 600,
    fontSize: 14,
  },
  userManagementMainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  changePassContainer: {
    backgroundColor: "#F8F8F8",
    display: "flex",
    flexDirection: "column",
    height: 248,
    padding: 16,
    gap: 16,
  },
  changePassWordButton: {
    width: 146,
    height: 40,
    backgroundColor: "white",
    padding: "8px, 32px, 8px, 32px",
    border: "1px solid #4F98BC",
    radius: "4px",
    lineHeight: "20px",
    textTransform: "none",
    color: "#4F98BC",
    fontWeight: 700,
    fontSize: 14,
  },
  questionTitleEdit: {
    marginTop: 30,
    fontSize: 14,
    fontWeight: 600,
    maxWidth: "100%",
    padding: "16px, 16px, 16px, 16px",
    borderRadius: "4px",
    "& .MuiTextField-root": {
      margin: "8px",
      width: "200px",
    },
    "& .MuiInputBase-input": {
      padding: "8px",
    },
  },
  boxQuestionTitle: {
    width: 200,
  },
  changeQuestionTitleContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  formQuestionTitleButtonContainer: {
    display: "flex",
    marginTop: 14,
    justifyContent: "flex-end",
    flexDirection: "row",
    gap: 16,
  },
  activityButtonActive: {
    backgroundColor: "#4F98BC",
    color: "white",
    fontWeight: 400,
    minWidth: 108,
    height: 32,
    fontSize: 14,
    textTransform: "none",
    padding: "8px",
    borderRadius: "4px",
    lineHeight: "20px",
    "&:hover": {
      backgroundColor: "#4F98BC",
    },
  },
  activityButtonInactive: {
    minWidth: 108,
    textTransform: "none",
    height: 32,
    backgroundColor: "#EDF5F8",
    color: "#4F98BC",
    fontWeight: 400,
    "&:hover": {
      backgroundColor: "#EDF5F8",
    },
    fontSize: 14,
  },
  commentsActivitiesMainContainer: {
    backgroundColor: "white",
    paddingTop: 16,
    paddingRight: 16,
    paddingLeft: 16,
    paddingBottom: 16,
    marginBottom: 24,
  },
  activitiesText: {
    fontSize: 14,
    fontWeight: 400,
  },
  activitiesTextContainer: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  commentActivityButtonContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 12,
  },
  commentsButtonActive: {
    backgroundColor: "#4F98BC",
    color: "white",
    fontWeight: 400,
    minWidth: 129,
    height: 32,
    fontSize: 14,
    padding: "8px",
    textTransform: "none",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#4F98BC",
    },
    lineHeight: "20px",
  },
  commentsButtonInactive: {
    // minWidth: 129,
    textTransform: "none",
    height: 32,
    backgroundColor: "#EDF5F8",
    color: "#4F98BC",
    fontWeight: 400,
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#EDF5F8",
    },
  },
  activitiesList: {
    display: "flex",
    marginTop: 24,
    flexDirection: "column",
    gap: 16,
    height: "400px",
    overflowY: "auto",
    overflowX: "hidden",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    webkitScrollbarWidth: "none",
  },
  timelineIconContainer: {
    position: "absolute",
    top: 19,
    left: 8,
  },
  activityContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 32,
    paddingRight: 8,
    radius: "4px",
    color: "#747474",
    fontSize: 14,
    fontWeight: 400,
    gap: 8,
    backgroundColor: "#F8F8F8",
    width: 341,
    "@media (max-width: 1280px)": {
      width: "100%",
    },
  },
  commentsTopContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  activitiesDate: {
    fontSize: 14,
    fontWeight: 600,
  },
  commentsMainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    marginTop: 16,
    marginBottom: 4,
    maxHeight: "400px",
    overflowY: "auto",
    overflowX: "hidden",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    webkitScrollbarWidth: "none",
  },

  commentContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 4,
    color: "#747474",
    fontSize: 14,
    fontWeight: 400,
    gap: 8,
    backgroundColor: "#F8F8F8",
    maxWidth: "100%",
    "@media (max-width: 1280px)": {
      width: "100%",
    },
    "&:hover": {
      backgroundColor: "#EDF5F8",
    },
    "&:active": {
      backgroundColor: "#EDF5F8",
    },
  },
  commentContainerActive: {
    backgroundColor: "#EDF5F8",
  },
  commentDateMenuContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
  },
  commentNameText: {
    fontWeight: 600,
    fontSize: 14,
  },
  threeDotsContainerComments: {
    cursor: "pointer",
  },
  pictureCommentContainer: {
    position: "absolute",
    top: 10,
    backgroundColor: "white",
    width: 22,
    height: 22,
    borderRadius: "50%",
    left: 4,
  },
  boxBorderComment: {
    width: "100%",
    position: "absolute",
    top: 46,
    borderBottom: "1px solid #e8e8e8",
  },
  tabsContainerCommentsRoot: {},
  addCommentField: {
    width: "100%",
    marginTop: 16,
    minHeight: 80,
    padding: "4px",
    border: "1px solid #ECECEC",
    borderRadius: "4px",
    fontSize: 14,
    color: "#747474",
    paddingLeft: 12,
    "& textarea": {
      fontSize: 14,
      color: "#747474",
    },
    "& > div": {
      alignItems: "flex-start",
    },
    "& button": {
      marginTop: 12,
    },
  },
  uploadFileButton: {
    width: 190,
    marginTop: 12,
    height: 40,
    backgroundColor: "#4F98BC",
    padding: "8px, 32px, 8px, 32px",
    radius: "4px",
    lineHeight: "20px",
    textTransform: "none",
    color: "white",
    fontWeight: 600,
    fontSize: 14,
  },
  filesMainContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 24,
  },
  uploadFilesMainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  typeComplianceFileText: {
    fontSize: 14,
    fontWeight: 700,
    color: "#747474",
    flex: 1,
  },
  uploadFileButtonMainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  uploadFileSquareButton: {
    cursor: "pointer",
    width: 602,
    height: 74,
    border: "1px dotted #ECECEC",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  uploadFileTextIconContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    alignItems: "center",
  },
  uploadFileTextSquaredButton: {
    font: "Open Sans",
    fontSize: 14,
    fontWeight: 600,
    color: "#4F98BC",
  },
  documentsMainContainer: {
    marginTop: 46,
  },
  uploadingFileDottedContainer: {
    maxWidth: "100%",
    border: "1px dotted #ECECEC",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    padding: "12px 16px 12px 16px",
  },
  fileNameAndActionsBar: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "12px 16px 12px 16px",
    backgroundColor: "#F8F8F8",
  },
  documentIconAndNameContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
    flex: 1,
  },
  documentNameText: {
    font: "Open Sans",
    fontSize: 14,
    fontWeight: 400,
    color: "#747474",
  },
  boxBar: {
    position: "absolute",
  },
  nameAndProgressBarContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: 4,
  },
  progressBar: {
    borderRadius: 4,
  },
  progressBarRoot: {
    height: 4,
    "&.MuiLinearProgress-root": {
      marginBottom: 0,
      marginTop: 0,
    },
  },
  checkDeleteContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
    marginLeft: 32,
  },
  emptyCircle: {
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid #D9D9D9",
  },
  deleteFileIconBox: {
    cursor: "pointer",
    marginTop: 4,
  },
  selectExpirationDateContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
  },
  selectExpirationDateText: {
    fontSize: 12,
    fontWeight: 600,
    color: "#747474",
    width: 182,
    marginTop: 8,
  },
  finishUploadButton: {
    width: 132,
    marginTop: 12,
    height: 40,
    backgroundColor: "#4F98BC",
    padding: "8px, 32px, 8px, 32px",
    radius: "4px",
    lineHeight: "20px",
    textTransform: "none",
    color: "#ffffff",
    fontWeight: 600,
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#4F98BC",
      opacity: 0.8,
    },
  },
  finishUploadButtonDisabled: {
    width: 132,
    marginTop: 12,
    height: 40,
    backgroundColor: "#D9D9D9",
    padding: "8px, 32px, 8px, 32px",
    radius: "4px",
    lineHeight: "20px",
    textTransform: "none",
    color: "#ffffff",
    fontWeight: 600,
    fontSize: 14,
  },
  expirationDateFieldContainer: {
    width: 310,
  },
  fileNameAndActionsBarMenu: {
    position: "relative",
    display: "flex",
    gap: 16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "12px 16px 12px 16px",
    backgroundColor: "#F8F8F8",
    flex: 1,
  },
  filesSmallButtonsContainer: {
    marginLeft: 12,
    display: "flex",
    flexDirection: "row",
    gap: 8,
    marginTop: 8,
  },
  expirationDateText: {
    fontSize: 14,
    fontWeight: 400,
    color: "#747474",
  },
  expirationDateContainer: {
    marginTop: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  approveFileButton: {
    width: "auto",
    paddingLeft: 16,
    paddingRight: 16,
    textTransform: "none",
    height: 32,
    border: "1px solid #4F98BC",
    backgroundColor: "white",
    fontSize: 14,
    color: "#4F98BC",
    borderRadius: "4px",
  },
  approvedFileButton: {
    width: "auto",
    paddingLeft: 16,
    paddingRight: 16,
    textTransform: "none",
    height: 32,
    border: "1px solid #328914",
    backgroundColor: "#E8F8CB",
    fontSize: 14,
    color: "#328914",
    borderRadius: "4px",
  },
  documentFilterText: {
    fontSize: 14,
    fontWeight: 700,
    color: "#C4C4C4",
    cursor: "pointer",
    "&:hover": {
      color: "#4F98BC",
    },
  },
  documentFiltersContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
  },
  filtersTableContainer: {
    marginTop: 24,
    display: "flex",
    flexDirection: "column",
    gap: 24,
  },
  downloadAllUploadFileButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: 12,
    marginTop: 24,
  },
  uploadFileButton2: {
    width: 140,
    height: 40,
    backgroundColor: "#4F98BC",
    padding: "8px, 32px, 8px, 32px",
    radius: "4px",
    lineHeight: "20px",
    textTransform: "none",
    color: "white",
    fontWeight: 600,
    fontSize: 14,
    "&.MuiButton-root:hover": {
      backgroundColor: "#4F98BC !important",
      opacity: 0.9,
    },
  },
  downloadAllButton: {
    width: 152,
    backgroundColor: "#ffffff",
    border: "1px solid #4F98BC",
    lineHeight: "20px",
    textTransform: "none",
    color: "#4F98BC",
    fontSize: "1rem",
  },
  ratingHeaderAndDownIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  topReviewContainer: {
    display: "flex",
    margin: 15,
    flexDirection: "column",
  },
  topRatingText: {
    fontSize: 18,
    fontWeight: 700,
    color: "#747474",
  },
  ratingStarsAndNumberContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
  },
  topRatingNumber: {
    fontSize: 18,
    marginTop: 4,
    fontWeight: 600,
    color: "#4D4D4D",
  },
  topRatingCommentContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    marginTop: 16,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 32,
    paddingRight: 8,
    radius: "4px",
    color: "#747474",
    fontSize: 14,
    fontWeight: 400,
    gap: 8,
    backgroundColor: "#EDF5F8",
    width: 341,
    "@media (max-width: 1280px)": {
      width: "100%",
    },
  },
  nonTopRatingCommentContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 12,
    paddingRight: 15,
    radius: "4px",
    color: "#747474",
    borderRadius: "4px",
    fontSize: 14,
    fontWeight: 400,
    gap: 8,
    backgroundColor: "#F8F8F8",
    maxWidth: "100%",
    "@media (max-width: 1280px)": {
      width: "100%",
    },
  },
  ratingCollapsableContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    transition: "height 0.3s ease",
    overflow: "hidden",
    height: "auto",
    // paddingTop: 16,
    boxSizing: "border-box",
  },
  ratingCollapsableContainerOpen: {
    height: "auto",
    maxHeight: "300px",
    overflowY: "scroll",
  },
  dummyBoxRating: {
    marginTop: 16,
  },
  topRatingCommenterInfo: {
    display: "flex",
    paddingLeft: "46px",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 8,
    alignItems: "center",
  },
  nameStarsContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    overflowWrap: "break-word",
  },
  topRatingCommenterName: {
    fontSize: 14,
    textWrap: "wrap",
    fontWeight: 600,
    color: "#747474",
  },
  ratingRootStyling: {
    fontSize: "16px !important",
    "&.MuiRating-root.Mui-disabled": {
      opacity: 1,
    },
  },
  ratingRootStylingLg: {
    fontSize: "28px !important",
    "&.MuiRating-root.Mui-disabled": {
      opacity: 1,
    },
  },
  menuDateContainer: {
    display: "flex",
    placeSelf: "baseline",
    flexDirection: "row",
    gap: 2,
    alignItems: "center",
    "& svg": {
      cursor: "pointer",
      fontSize: 20,
      marginLeft: 4,
    },
  },
  topRatingDate: {
    fontSize: 14,
    fontWeight: 400,
    color: "#747474",
    paddingBottom: 8,
  },
  topRatingReviewComment: {
    fontSize: 14,
    fontWeight: 400,
    color: "#747474",
  },
  totalReviewsContainer: {
    // marginTop: 16,
    border: "1px solid #4F98BC",
    borderRadius: "4px",
    padding: "15px 16px 15px 16px",
    display: "flex",
    justifyContent: "space-between",
  },
  totalReviewsText: {
    fontSize: 14,
    fontWeight: 600,
    color: "#4F98BC",
  },
  viewAllContainer: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
  },
  topRatingPictureContainer: {
    position: "absolute",
    top: 10,
    width: 32,
    height: 32,
    borderRadius: "50%",
  },
  topRatingPicture: {
    width: 32,
    height: 32,
    borderRadius: "50%",
  },
  linearProgressBar: {
    backgroundColor: "#4F98BC",
    "&.MuiLinearProgress-bar": {
      backgroundColor: "black",
    },
  },
  colorPrimary: {
    backgroundColor: "#D9D9D9",
  },
  barColorPrimaryRed: {
    backgroundColor: "#F07A8C",
  },
  barColorPrimary: {
    backgroundColor: "#4F98BC",
  },
  barColorPrimaryGreen: {
    backgroundColor: "#9CBB65",
  },
  barColorPrimaryYellow: {
    backgroundColor: "#EFC945",
  },
  ratingStyle: {
    "&.MuiRating-root.Mui-disabled": {
      opacity: 1,
    },
  },
  appBarWorkticketPreview: {
    position: "relative",
  },
  appBarTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
    textAlign: "right",
  },
  btnJobApply: {
    textTransform: "capitalize",
    minWidth: 150,
  },
  allRatingsHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 26,
    marginBottom: 32,
    alignItems: "center",
  },
  allRatingsHeaderText: {
    fontSize: 16,
    fontWeight: 600,
    color: "#4D4D4D",
  },
  allRatingsAverageText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  variantBackground: {
    backgroundColor: "#F8F8F8",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    gap: 24,
    padding: 32,
  },
  tradingDrawerFormContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 32,
    gap: 24,
  },
  drawerFieldsFormContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    marginRight: 32,
    marginLeft: 32,
  },
  drawerTradingButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: 48,
    marginTop: 32,
    paddingTop: 32,
    paddingBottom: 32,
    marginRight: 28,
  },
  addPricingVariationButton: {
    width: 187,
    height: 40,
    backgroundColor: "white",
    border: "1px solid #4F98BC",
    padding: "8px, 32px, 8px, 32px",
    radius: "4px",
    lineHeight: "20px",
    textTransform: "none",
    marginLeft: 32,
    color: "#4F98BC",
    fontWeight: 600,
    fontSize: 14,
  },
  deletePricingVariationButton: {
    width: 148,
    height: 40,
    backgroundColor: "white",
    border: "1px solid #EA5168",
    padding: "8px, 32px, 8px, 32px",
    radius: "4px",
    lineHeight: "20px",
    textTransform: "none",
    marginLeft: 32,
    color: "#EA5168",
    fontWeight: 600,
    fontSize: 14,
  },
  buttonVariationBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: 32,
  },
  dummyThreedotContainer: {
    display: "hidden",
    opacity: 0,
  },
  menuIcon: {
    width: 24,
    height: 24,
    flexShrink: 0,
    borderRadius: 4,
    background: "var(--Grey-200, #ECECEC)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  menuText: {
    paddingLeft: 8,
    color: "var(--Grey-400, #747474)",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  removeMarginGrid: {},
  addRatingModalEndContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  threeDotVariantContainer: {
    position: "absolute",
    right: 0,
    marginTop: 12,
    top: 0,
    cursor: "pointer",
  },
  mainFilesContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "90%",
  },
  stateZipFilterContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 16,
    width: 400,
  },
  actionItemButton: {
    textAlign: "right",
    paddingTop: 24,
    paddingRight: 24,
    paddingBottom: 24,
  },
  noFilesContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    alignItems: "center",
    justifyContent: "center",
    height: 200,
  },
  dateRangeContainer: {
    backgroundColor: "#EDF5F8",
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 14,
    display: "flex",
    borderRadius: 4,
    justifyContent: "center",
    lineHeight: 1.5,
    "& p": {
      color: "#4f98bc",
      marginRight: 4,
      fontSize: 14,
    },
  },
  dialogFormContainer: {},
  dialogFormLabel: {
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 600,
    color: "#747474",
    marginTop: 12,
  },
  dialogFormText: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    color: "#747474",
    "& > div": {
      marginTop: 8,
    },
  },
  progressIndicator: {
    fontSize: 10,
    fontWeight: 400,
    color: "#747474",
    stripedRow: {
      backgroundColor: "#f5f5f5",
    },
    borderRow: {
      "& > .MuiTableCell-root": {
        borderBottom: "none",
        borderTop: "none",
      },
    },
  },
  locationsTitleContainer: {
    marginTop: 24,
    marginBottom: 24,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  locationsSubHeader: {
    fontSize: 18,
    fontWeight: 700,
    color: "#17457A",
    height: 22,
  },
  actionFormWrapper: {
    paddingRight: 24,
    marginTop: 16,
    marginBottom: 16,
  },
  locationAccordionRoot: {
    marginBottom: 16,
    borderRadius: 4,
  },
  locationBadge: {
    fontSize: 14,
    border: "1px solid #ECECEC",
    backgroundColor: "#FFFFFF",
    color: "#747474",
    padding: "4px 8px 4px 8px",
    borderRadius: 50,
    display: "inline-block",
  },
  locationJobBadge: {
    fontSize: 14,
    border: "1px solid #ECECEC",
    backgroundColor: "#FFFFFF",
    color: "#747474",
    padding: "4px 8px 4px 8px",
    borderRadius: 50,
    "& button": {
      width: 24,
      height: 24,
    },
    "& svg": {
      fontSize: 14,
    },
  },
  locationListMenu: {
    position: "absolute",
    right: 0,
    top: 4,
    "&.MuiButtonBase-root:hover": {
      backgroundColor: "transparent",
    },
  },
  locationListMenuExpanded: {},
  arrowDownLocation: {
    right: 48,
    top: 18,
    position: "absolute",
  },
  infoAlert: {
    border: "none",
  },
  contentFormDialog: {
    fontSize: 16,
    textAlign: "center",
  },
  textOverflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "90%",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    wordBreak: "break-word",
    whiteSpace: "normal",
  },
  cancelButton: {
    display: "flex",
    width: 92,
    height: 32,
    padding: "8px 32px",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
    border: "1px solid  #4F98BC",
    background: "#FFF",
    color: "#4F98BC",
    textTransform: "capitalize",
    boxShadow: "none",
    "&:hover": {
      background: "#FFF",
      opacity: 0.8,
      boxShadow: "none",
    },
  },
  buttonConfirm: {
    padding: "8px 32px",
    borderRadius: 4,
    border: "1px solid #4F98BC",
    color: "#ffffff",
    height: 32,
    fontSize: 14,
    textTransform: "capitalize",
    backgroundColor: "#4f97bb",
    "&:hover": {
      background: "#4F98BC",
      opacity: 0.8,
    },
  },
  inputLabel: {
    color: "#C4C4C4",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 600,
    paddingBottom: 10,
  },
  inputField: {
    borderRadius: 4,
    border: "solid 1px #d6dde1 !important",
    fontSize: 14,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "& .MuiInputBase-input": {
      padding: 10,
      fontSize: 14,
      fontStyle: "normal",
    },
    "&:hover": {
      border: "solid 1px #359be0",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiInputBase-inputMarginDense": {
      margin: 0,
    },
  },
  arrowDownQuestionWithPermission: {
    right: 15,
  },
  drawerErrorFormContainer: {
    display: "flex",
    //start at the bottom
    flexDirection: "column",
    gap: 24,
    marginTop: 32,
    justifyContent: "flex-end",
  },
  checkboxListContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 8,
  },
  filterCheckbox: {
    color: "#D9D9D9",
    border: "none",
    padding: 0,
    paddingRight: 5,
    "& + .MuiFormControlLabel-label": {
      fontSize: 14,
      marginLeft: 0,
      color: "#747474",
    },
  },
  formHelperText: {
    color: theme.palette.error.main,
  },
  checkboxLabel: {
    color: "#747474",
    fontSize: 14,
  },
}));

export default useStyles;
